import { CheckApiKey, CheckPolicy, Resource } from '../../framework/permission-util'
import { RT } from '../permissions'
import Executive from '../../framework/rmi-util'
import { register } from '../../framework/rmi-server-util'
import { InfoType } from './info-types.entities'
import { getInfoTypes, getInfoType, upsertInfoType, deleteInfoType, exportInfoTypes } from './InfoTypeTechnical'
import { InfoTypeExportStats } from './types'

export default class InfoTypeExecutive extends Executive {

	initServer() {
	}

	@CheckPolicy()
	async getInfoTypes(): Promise<InfoType[]> {
		return await getInfoTypes()
	}

	// Note this will be called externally (from Toolbox). In that case we do not have a user context and the permission check would fail. Thus, we only check for a valid api key here.
	@CheckApiKey()
	async getInfoTypesExternal(): Promise<InfoType[]> {
		return await getInfoTypes()
	}

	@CheckApiKey()
	async getInfoType(id: string): Promise<InfoType> {
		return await getInfoType(id)
	}

	@CheckApiKey()
	async upsertInfoType(infoType: InfoType): Promise<InfoType> {
		return await upsertInfoType(infoType)
	}

	@CheckApiKey()
	async deleteInfoType(id: string): Promise<void> {
		return await deleteInfoType(id)
	}

	// Only for Postman testing - the cronjob will directly call the technical
	@CheckApiKey()
	async exportInfoTypes(): Promise<Map<string, InfoTypeExportStats>> {
		return await exportInfoTypes()
	}

}

register(InfoTypeExecutive)
