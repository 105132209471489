<template>
	<div v-if="showInitialWidget" class="ContractorDashboardWidget">
		<div class="contractor-dashboard-widget-container">
			<span class="contractor-dashboard-widget-container-title">{{ $t('text.contractorDashboardTitle', { username: $store.state.loggedInUser?.fields.name.de }) }}</span>
			
			<!-- Contract Insights Section -->
			<template v-if="contractorHasContractsThatNeedAttention">
				<div class="info-container contractor-dashboard-widget-container-inner">
					<div class="contractor-dashboard-widget-container-inner-title">
						<span>{{ $t('text.contractInsights') }}</span>
					</div>
					<!-- Terminated Contracts Section -->
					<div v-if="terminatedContracts.length" class="contracts-grid" :class="{ 'single-contract': terminatedContracts.length === 1 }">
						<ContractCardTiny 
							v-for="contract in terminatedContracts" 
							:key="contract.id" 
							:contract="contract"
							:client-name="clientNames[contract.contractTemplate?.contractSequence?.clientId]"
						/>
					</div>

					<!-- Active Contracts Section -->
					<div v-if="activeContracts.length" class="contracts-grid" :class="{ 'single-contract': activeContracts.length === 1 }">
						<ContractCardTiny 
							v-for="contract in activeContracts" 
							:key="contract.id" 
							:contract="contract"
							:client-name="clientNames[contract.contractTemplate?.contractSequence?.clientId]"
						/>
					</div>
					<div class="contractor-dashboard-widget-container-inner-info">{{ $t('text.contractsDashboardInfo') }}</div>
					<div class="contractor-dashboard-widget-container-inner-button">
						<v-btn class="blueButton" @click="$router.push('/contractSigning')">{{ $t('text.viewContracts') }}</v-btn>
					</div>
				</div>
			</template>
			
			<!-- Error Container for Missing Contractor Details -->
			<template v-else-if="contractor && hasMissingFields">
				<div class="error-container contractor-dashboard-widget-container-inner">
					<div class="contractor-dashboard-widget-container-inner-title">
						<v-icon size="24px">mdi-alert</v-icon>
						<span>{{ $t('text.completeContractDetails') }}</span>
					</div>
					<span class="contractor-dashboard-widget-container-inner-info" v-html="$t('text.completeContractDetailsText', { marketplace: currentClientTitle })" />
					<span class="contractor-dashboard-widget-container-inner-link" @click="openContractorDialog()">{{ $t('text.completeData') }}</span>
				</div>
			</template>

			<!-- Pending Approval Warning -->
			<template v-else-if="isInPendingState && !isExistingServiceProvider">
				<div class="warning-container contractor-dashboard-widget-container-inner">
					<div class="contractor-dashboard-widget-container-inner-title">
						<v-icon size="24px">mdi-clock-outline</v-icon>
						<span>{{ $t('text.pendingApproval') }}</span>
					</div>
					<span class="contractor-dashboard-widget-container-inner-info" v-html="$t('text.pendingApprovalDetailsText', { marketplace: currentClientTitle })" />
				</div>
			</template>

			<!-- Getting Started Section - Only for New Service Providers -->
			<div v-if="!isExistingServiceProvider && showGettingStarted" class="contractor-dashboard-widget-container-inner">
				<div class="contractor-dashboard-widget-container-inner-list">
					<div class="inner-list-title">
						<v-icon size="24px">mdi-rocket-launch</v-icon> 
						<span>{{ $t('text.gettingStarted') }}</span>
					</div>
					<div class="inner-list-item" :class="{ completed: isBusinessProfileValid }">
						<v-icon size="24px">mdi-check-circle</v-icon>
						<div>
							<span>{{ $t('text.setUpYourBusinessProfile') }}</span>
							<span>{{ $t('text.setUpBusinessProfileInfoText') }}</span>
						</div>
						<a href="/businessProfile">{{ $t('text.setUpBusinessProfile') }}</a>
					</div>
					<div class="inner-list-item" :class="{ completed: hasServices }">
						<v-icon size="24px">mdi-check-circle</v-icon>
						<div>
							<span>{{ $t('text.createYourServices') }}</span>
							<span>{{ $t('text.createYourServicesInfoText') }}</span>
						</div>
						<a href="/services">{{ $t('text.designYourServices') }}</a>
					</div>
					<div class="inner-list-item" :class="{ completed: !hasMissingFields, disabled: !contractor }">
						<v-icon size="24px">mdi-check-circle</v-icon>
						<div>
							<span>{{ $t('text.completeContractDetails') }}</span>
							<span>{{ $t('text.completeContractDetailsInfoText') }}</span>
						</div>
						<a @click="openContractorDialog()" style="cursor: pointer;">{{ $t('text.completeData') }}</a>
					</div>
				</div>
			</div>
		</div>
		<StepperDialog ref="contractorDialog" v-model="contractorClone" v-model:stepId="step" group="Contractor" :onConfirm="updateContractor" :confirm-label="$t('text.completeRegistration')"
			:order="[ steps ]" :disableConfirmButton="step === 'businessInformation' ? hasFormOneError : hasFormTwoError" :height="'calc(100vh - 5px)'" class="ContractStepperDialog" @close="resetContractErrors"
		>
			<template #title>
				<v-toolbar-title><span>{{ $t("text.contractorData") }}</span></v-toolbar-title>
			</template>
			<Step id="businessInformation" group="Contractor" icon="mdi-briefcase-variant" class="StepWithoutStepper" :force-status="hasFormOneError ? 'error' : undefined">
				<ContractorForm v-if="step === 'businessInformation'" v-model="contractorClone" :step="step" @update:modelValue="contractorClone = $event" @update:hasFormError="hasFormOneError = $event" />
			</Step>
			<Step id="contractingInformation" group="Contractor" icon="mdi-gavel" class="StepWithoutStepper" :disabled="hasFormOneError" :force-status="hasFormTwoError ? 'error' : undefined">
				<ContractorForm v-if="step === 'contractingInformation'" v-model="contractorClone" :step="step" @update:modelValue="contractorClone = $event" @update:hasFormError="hasFormTwoError = $event" />
			</Step>
		</StepperDialog>
	</div>
</template>
<script lang="ts">
import Common from '../../mixins/Common.vue'
import ContractExecutive from '../../../../api2/src/modules/contract/ContractExecutive'
import StepperDialog from '../../components/common/StepperDialog.vue'
import ContractorForm from '../../components/contract/ContractorForm.vue'
import { metadata } from '../../../../api2/src/framework/model-util'
import ContractCardTiny from '../../components/contract/ContractCardTiny.vue'
import Loading from '../../mixins/Loading.vue'
import Step from '../../views/applications/packageDesigner/Step.vue';
import { Contract, Contractor, ContractStatus } from '../../../../api2/src/modules/contract/contract.entities'

export default {
	name: 'ContractorDashboardWidget',
	components: { ContractorForm, ContractCardTiny, StepperDialog, Step },
	mixins: [ Common, Loading ],
	data: () => ({
		contractor: null as Contractor | null,
		contractorClone: null,
		hasFormOneError: false,
		hasFormTwoError: false,
		contractorHasContractsThatNeedAttention: false,
		contractsThatNeedAttention: [] as Contract[],
		clientNames: {},
		isBusinessProfileValid: false,
		hasServices: false,
		step: 'businessInformation',
		steps: ['businessInformation', 'contractingInformation'],
		contractsByContractor: {} as Record<string, Contract[]>,
		hasActiveOrExpiredServices: false,
		isLoading: true
	}),
	computed: {
		showGettingStarted() {
			return !this.isLoading && (!this.isBusinessProfileValid || !this.hasServices || this.hasMissingFields)
		},
		serviceProvider() {
			return this.$store.state.isImpersonation ? this.$store.state.selectedServiceProvider : this.$store.state.loggedInUser?.fields?.serviceProvider?.de
		},
		clientAssignment() {
			return this.serviceProvider?.fields?.clientAssignments?.de?.find(x => x.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id)
		},
		loggedInEmail() {
			if (!this.userIsServiceProvider) {
				return null
			}
			return this.serviceProvider?.fields?.userAccount?.de?.fields?.email?.de
		},
		hasMissingFields() {
			const contractorFields = Object.entries(metadata.Contractor.fields).filter(([_, value]) => value.required).map(([key]) => key)
			const businessProfileDataFields = Object.entries(metadata.BusinessProfileData.fields).filter(([_, value]) => value.required).map(([key]) => key)
			return !this.contractor || !this.contractor.businessProfileData || contractorFields.some(x => !this.contractor[x]) || businessProfileDataFields.some(x => !this.contractor.businessProfileData[x]) || (!this.contractor.businessProfileData.dontHaveUidNumber && !this.contractor.businessProfileData.uidNumber)
		},
		isInPendingState() {
			return this.clientAssignment?.fields?.status?.de === 'pending'
		},
		showInitialWidget() {
			return this.userIsServiceProvider && (this.showGettingStarted || this.isInPendingState || this.contractorHasContractsThatNeedAttention)
		},
		currentClientTitle() {
			return this.$store.state.selectedClient.fields.clientId?.de
		},
		terminatedContracts() {
			return this.contractsThatNeedAttention.filter(contract => 
				this.isTerminated(contract)
			) as Contract[]
		},
		activeContracts() {
			return this.contractsThatNeedAttention.filter(contract => 
				!this.isTerminated(contract)
			) as Contract[]
		},
		isExistingServiceProvider() {
			return this.clientAssignment?.fields?.status?.de === 'approved' && 
				   this.hasActiveOrExpiredServices
		},
	},
	watch: {
		showInitialWidget: {
			handler() {
				this.fetchContractsThatNeedAttention()
			},
		},
	},
	methods: {
		resetContractErrors() {
			this.hasFormOneError = false
			this.hasFormTwoError = false
		},
		isTerminated(contract: Contract) {
			return contract.status === ContractStatus.TERMINATED || !!contract.endDate
		},
		openContractorDialog() {
			this.contractorClone = JSON.parse(JSON.stringify(this.contractor))
			if (this.$refs.contractorDialog) {
				this.$refs.contractorDialog.open()
			}
		},
		async getContractsAndContractors() {
			let contractExecutive = new ContractExecutive(this)
			if (!this.loggedInEmail) {
				this.showError('Not Authorized!')
// TODO: what was the intention of this?
//       i had to remove it because this triggers login a second time
//				this.showError('Not Authorized!')
//				setTimeout(() => {
//					this.$emit('show-login')
//				}, 1000)
				return
			}
			const clientId = this.$store.state.selectedClient.sys.id
			const providerId = this.$store.state.selectedServiceProvider.sys.id
			const result =  await contractExecutive.getContractsAndContractorsByEmail(providerId, this.loggedInEmail)
			this.contractor = result.contractors.find(contractor => contractor.clientId === clientId)
			this.contractsByContractor = result.contractsByContractor
			this.clientNames = result.clientNames
		},
		async updateContractor() {
			if (this.step === 'businessInformation') {
				return true
			}
			await this.loadingOverlay(async () => {
				try {
					let contractExecutive = new ContractExecutive(this)
					await contractExecutive.bulkUpdateContractors([this.contractorClone])
					this.getContractsAndContractors()
				}
				catch (error) {
					this.showError(error.message)
				}
			}, 'updateContractor')
		},
		async fetchContractsThatNeedAttention() {
			await this.loadingOverlay(async () => {
				try {
					let contractExecutive = new ContractExecutive(this)
					const result = await contractExecutive.getContractsThatNeedAttention(this.contractor.id)
					// show terminated contracts first (based on designs)
					this.contractsThatNeedAttention = result
					this.contractorHasContractsThatNeedAttention = result.length > 0
				}
				catch (error) {
					this.showError(error.message)
				}
			}, 'fetchContractsThatNeedAttention')
		},
		async checkServiceProviderFields() {
			await this.loadingOverlay(async () => {
				try {
					const result = await this.$httpGet(`/check-missing-data?serviceProviderId=${this.serviceProvider.sys.id}&clientId=${this.$store.state.selectedClient.sys.id}&serviceLocale=${this.serviceLocale}`)
					this.isBusinessProfileValid = result.status === 'complete'
				}
				catch (error) {
					this.showError(error.message)
				}
			}, 'checkServiceProviderFields')
		},
		async getProducts() {
			await this.loadingOverlay(async () => {
				try {
					const statuses = ['active', 'active_saved', 'pending', 'deactivated', 'archived', 'expired', 'saved']
					const result = await this.$httpGet(`/product-count?serviceProviderId=${this.serviceProvider.sys.id}&client=${this.$store.state.selectedClient.sys.id}&status=${statuses}`)
					this.hasServices = result.count > 0
				}
				catch (error) {
					this.showError(error.message)
				}
			}, 'getProducts')
		},
		async getActiveOrExpiredServices() {
			await this.loadingOverlay(async () => {
				try {
					const statuses = ['active', 'expired']
					const result = await this.$httpGet(`/product-count?serviceProviderId=${this.serviceProvider.sys.id}&client=${this.$store.state.selectedClient.sys.id}&status=${statuses}`)
					this.hasActiveOrExpiredServices = result.count > 0
				}
				catch (error) {
					this.showError(error.message)
				}
			}, 'getActiveOrExpiredServices')
		},
		async initializeData() {
			try {
				this.isLoading = true
				
				await Promise.all([
					this.getContractsAndContractors(),
					this.getProducts(),
					this.checkServiceProviderFields(),
					this.getActiveOrExpiredServices(),
					this.fetchContractsThatNeedAttention()
				])
			}
			catch (error) {
				console.error('Error initializing data', error)
			}
			finally {
				this.isLoading = false
			}
		}
	},
	async created() {
		await this.initializeData()
	},
}
</script>
<style lang="scss" scoped>
.ContractorDashboardWidget { padding: 20px 48px 12px 48px; }
.contractor-dashboard-widget-container { 
	background-color: white; 
	padding: 32px 64px; 
	border-radius: 4px; 
	&-title {
		font-size: 20px;
		font-weight: 600;
		line-height: 25px;
		letter-spacing: -0.196px;
	}
	&-inner {
		margin-top: 32px;
		border-radius: 4px;
		padding: 24px;
		padding-left: 18px;
		display: flex;
    	flex-direction: column;

		&:not(:first-child) {
			margin-top: 24px
		}

		&.error-container {
			border: 1px solid #DBDBDB;
			border-left: 6px solid #F34545;
			.contractor-dashboard-widget-container-inner-title {
				color: #F34545;
			}
		}
		&.warning-container {
			border: 1px solid #DBDBDB;
			border-left: 6px solid #FFB400;
			.contractor-dashboard-widget-container-inner-title {
				color: #FFB400;
			}
		}
		&.info-container {
			position: relative;
			padding-left: 24px;
			
			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 6px;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
			
			.contractor-dashboard-widget-container-inner-title {
				color: #00AEEF;
			}
		}
		&-title {
			font-size: 17px;
			font-weight: 600;
			line-height: 22px;
			letter-spacing: -0.071px;
			padding-bottom: 16px;
			> i {
				padding-right: 6px;
			}
		}
		&-info {
			font-size: 15px;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: -0.037px;
		}
		&-link {
			font-size: 15px;
			font-weight: 600;
			line-height: 20px;
			letter-spacing: -0.037px;
			color: #00AEEF;
			cursor: pointer;
			padding-top: 4px;
		}
		&-list {
			padding-top: 0;
			> .inner-list-title {
				padding-bottom: 16px;
				display: flex;
				align-items: center;
				> span {
					font-size: 17px;
					font-weight: 600;
					line-height: 22px;
					letter-spacing: -0.071px;
					padding-left: 16px;
				}
			} 
			> .inner-list-item {
				height: 70px;
				display: flex;
				align-items: center;
				> i {
					color: #8F8F8F;
				}
				> div {
					padding-left: 16px;
					display: flex;
					flex-direction: column;
					flex-grow: 1;
					> span:first-child {
						font-size: 17px;
						font-weight: 600;
						line-height: 22px;
						letter-spacing: -0.071px;
					}
					> span:last-child {
						font-size: 15px;
						font-weight: 400;
						line-height: 20px;
						letter-spacing: -0.037px;
					}
				}

				> a {
					white-space: nowrap;
					font-size: 15px;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: -0.037px;
				}
				&.completed {
					> i {
						color: #CBEDB6;
					}
					> a {
						pointer-events: none;
						color: #8F8F8F;
					}
				}
			}
		}
	}
}
.contracts-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 16px;
	margin-bottom: 16px;

	&.single-contract {
		grid-template-columns: 1fr;
	}
}

.contractor-dashboard-widget-container-inner-button {
	display: flex;
	justify-content: flex-start;
	margin-top: 16px;
}
</style>

<style lang="scss">
.ContractStepperDialog .StepWithoutStepper {
	margin-top: 0px;
	position: relative;
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}
</style>