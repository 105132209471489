<template>
	<Disclosure :title="$t('text.generalInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message"
		:lock="!userMayEditFields" data-cy="generalInfo" ref="SECTION_generalInfo"
		eager
	>
		<div v-if="(hasField('generalInfo','topProduct', model) || hasField('generalInfo','freeProduct', model) || hasField('generalInfo','paidAd', model)) && userIsOperator"
			class="field left-border"
		>
			<v-label v-if="hasField('generalInfo','topProduct', model)">{{ $t('text.topProduct') }}</v-label>
			<v-row v-if="hasField('generalInfo','topProduct', model)">
				<v-radio-group inline v-model="model.fields.topProduct.de" @update:modelValue="toggleFree()" color="blue">
					<v-radio v-for="({ value, label }) in this.yesNoOptions" :key="'topProduct_' + label" :value="value" :label="label" />
				</v-radio-group>
			</v-row>
			<v-label v-if="hasField('generalInfo','freeProduct', model)">{{ $t('text.freeProduct') }}</v-label>
			<v-row v-if="hasField('generalInfo','freeProduct', model)">
				<v-radio-group inline v-model="model.fields.freeProduct.de" @update:modelValue="toggleTop()" color="blue">
					<v-radio v-for="({ value, label }) in this.yesNoOptions" :key="'freeProduct_' + label" :value="value" :label="label" />
				</v-radio-group>
			</v-row>
			<v-label v-if="hasField('generalInfo','paidAd', model)">{{ $t('text.paidAd') }}</v-label>
			<v-row v-if="hasField('generalInfo','paidAd', model)">
				<v-radio-group inline v-model="model.fields.paidAd.de" color="blue">
					<v-radio v-for="({ value, label }) in this.yesNoOptions" :key="'paidAd_' + label" :value="value" :label="label" />
				</v-radio-group>
			</v-row>
			<p class="helpText" v-html="$t('text.productFlagHelp')"/>
		</div>
		<div v-if="hasField('generalInfo','images', model)" class="field left-border">
			<v-label>{{ $t('text.images') }} <span class="error-text">({{ $t('text.required') }})</span></v-label>
			<!-- TODO: images may be undef -->
			<ImagesField ref="imageField" v-model="model.fields.images.de" @on-image-errors="onImageErrors" @toggle-loading="toggleLoading" required />
		</div>
		<div v-if="hasField('generalInfo','faqGroup', model)" class="field left-border">
			<v-label>{{ $t('text.faq') }}</v-label>
			<v-select variant="outlined" density="compact" return-object
				v-model="model.fields.faqGroup.de"
				:items="faqGroups"
				:item-title="item => item.fields?.title?.[selectedLocale] ?? ''"
			/>
			<p class="helpText" v-html="$t('text.faqDesc')"/>
		</div>
		<div v-if="hasField('generalInfo','averageDurationOfStay', model)" class="field left-border">
			<v-label>{{ $t('text.averageDurationOfStayInMinutes') }}</v-label>
			<v-text-field variant="outlined" density="compact" hide-details v-mask="'####'"
				v-model="model.fields.averageDurationOfStayInMinutes.de"
				style="width:50%;margin-bottom:12px;padding-right:20px"
			/>
			<p class="helpText" v-html="$t('text.averageDurationOfStayInMinutesDesc')"/>
		</div>
		
		<!-- <div v-if="hasInfosField && areInfoTypesDone">
			<Field typeName="Service" fieldName="infos" v-model="model.fields.infos" :disabled="!userMayEditFields" :options="infoTypeOptions" :child-locale="$store.state.serviceLocale" />
		</div> -->
		
	</Disclosure>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import isEqual from 'lodash/isEqual'
import ImagesField from '../common/ImagesField.vue'
import Field from '../fields/Field.vue'
import InfoTypeExecutive from '../../../../api2/src/modules/info-types/InfoTypeExecutive'

export default {
	name: 'GeneralInfo',
	components: { Disclosure, ImagesField, Field },
	mixins: [ Common ],
	props: {
		product: Object,
		updateModel: Boolean,
	},
	data() {
		return {
			model: {},
			initData: {},
			sectionMessage: {
				error: false,
				message: ''
			},
			faqGroups: [],
			mappedInfoTypes: [],
			areInfoTypesDone: false
		}
	},
	computed: {
		hasInfosField() {
			return this.hasField('generalInfo','infos', this.model)
		},
		infoTypeOptions() {
			return this.mappedInfoTypes.filter(mapped =>
				!this.model.fields.infos.de.some(info => info?.fields?.infoType?.value === mapped.value)
			)
		}
	},
	watch: {
		updateModel() {
			this.model = this.valueToModel(this.product)
			this.setInitData()
			this.transformInfos()
		},
	},
	methods: {
		async getInfoTypes() {
			this.areInfoTypesDone = false
			let infoTypeExecutive = new InfoTypeExecutive(this)
			const infoTypes = await infoTypeExecutive.getInfoTypes()
			this.mappedInfoTypes = infoTypes.map(x => ({ value: x.key, title: x.name }))
			this.$emit('setInfoTypes', this.mappedInfoTypes)
			this.transformInfos()
			this.areInfoTypesDone = true
		},
		transformInfos() {
			const infos = []
			this.product.fields.infos.de.forEach(info => {
				const foundInfoType = this.mappedInfoTypes.find(x => x.value === info.infoTypeId)
				const transformedInfo = { sys: { id: info.id, contentType: { sys: { id: 'Info' } } }, fields: { infoType: foundInfoType, description: info.description } }
				infos.push(transformedInfo)
			});
			this.model.fields.infos.de = infos
		},
		toggleLoading(value) {
			this.$emit('isLoading', value)
		},
		onImageErrors(n) {
			if (n.length > 0) {
				this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
			}
			else {
				this.resetSectionError(this.sectionMessage)
			}
		},
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		setInitData() {
			const initModel = JSON.parse(JSON.stringify(this.model))
			if (this.hasField('generalInfo','topProduct', this.model)) {
				this.initData.topProduct = initModel.fields.topProduct
			}
			if (this.hasField('generalInfo','freeProduct', this.model)) {
				this.initData.freeProduct = initModel.fields.freeProduct
			}
			if (this.hasField('generalInfo','paidAd', this.model)) {
				this.initData.paidAd = initModel.fields.paidAd
			}
			if (this.hasField('generalInfo','images', this.model)) {
				this.initData.images = initModel.fields.images
			}
			if (this.hasField('generalInfo','averageDurationOfStay', this.model)) {
				initModel.fields.averageDurationOfStayInMinutes.de = initModel.fields.averageDurationOfStayInMinutes.de?.toString?.() ?? ''
				this.initData.averageDurationOfStayInMinutes = initModel.fields.averageDurationOfStayInMinutes
				if (initModel.fields.averageDurationOfStayInMinutes?.de === '') {
					this.initData.averageDurationOfStayInMinutes.de = null
				}
			}
			if (this.hasField('generalInfo','faqGroup', this.model)) {
				this.initData.faqGroup = initModel.fields.faqGroup
			}
		},
		sendData() {
			let data = {}

			if (this.hasField('generalInfo','topProduct', this.model)) {
				data.topProduct = this.model.fields.topProduct
			}
			if (this.hasField('generalInfo','freeProduct', this.model)) {
				data.freeProduct = this.model.fields.freeProduct
			}
			if (this.hasField('generalInfo','paidAd', this.model)) {
				data.paidAd = this.model.fields.paidAd
			}
			if (this.hasField('generalInfo','images', this.model)) {
				data.images = this.model.fields.images
			}
			if (this.hasField('generalInfo','averageDurationOfStay', this.model)) {
				data.averageDurationOfStayInMinutes = this.model.fields.averageDurationOfStayInMinutes
			}
			if (this.hasField('generalInfo','faqGroup', this.model)) {
				data.faqGroup = this.model.fields.faqGroup
			}
			if (this.hasInfosField) {
				data.infos = {
					de: this.model.fields.infos.de.map(x => ({
						id: x.sys.id, infoTypeId: x.fields?.infoType?.value, description: x.fields.description, title: { de: `${this.model.fields?.title?.de ?? this.model.fields?.title?.[this.defaultLocale.code]} - ${x.fields.infoType?.title?.de ?? x.fields?.infoType?.title?.[this.defaultLocale.code]}` } })) }
			}

			data.changed = !isEqual(data, this.initData)

			return data
		},
		validateAllFields() {
			let allFieldsAreValid = true
			this.resetSectionError(this.sectionMessage)

			if (!this.$refs.imageField?.validateImage()) {
				allFieldsAreValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
				return allFieldsAreValid
			}

			if (this.hasInfosField && this.model.fields?.infos?.de?.length) {
				allFieldsAreValid = !this.model.fields?.infos?.de.some(x => !x.fields?.infoType || !this.validateTextLocales(x.fields.description))
				if (!allFieldsAreValid) this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
			}

			return allFieldsAreValid
		},
		toggleFree() {
			if (this.model.fields.topProduct.de) this.model.fields.freeProduct.de = false
		},
		toggleTop() {
			if (this.model.fields.freeProduct.de) this.model.fields.topProduct.de = false
		},
		async getFAQs() {
			try {
				let res = await this.$httpGet(`/faqs?serviceProviderId=${this.$store.state.selectedServiceProvider.sys.id}`)
				this.faqGroups = res.faqGroups

				if (this.faqGroups) {
					this.faqGroups.sort(this.compare)
				}

				//add an empty object to the top to make possible a deselect for v-select as v-select does not have a built-in way to select a blank option
				this.faqGroups.unshift({})
			}
			catch (error) {
				this.showError(error)
			}
		},
	},
	created() {
		this.model = this.valueToModel(this.product)
		this.setInitData()
		if (this.hasInfosField) {
			this.getInfoTypes()
		}
	},
	mounted() {
		this.getFAQs()
	},
}
</script>

<style scoped>
</style>