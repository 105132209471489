<template>
	<div>
		<v-label v-if="label">{{ label }}</v-label>
		<p>{{ helpText }}</p>
		<v-select
			ref="select"
			v-model="model"
			:items="allOptions"
			:placeholder="placeholder"
			:item-title="(item) => item.title?.[serviceLocale] || item.title?.de || item.title"
			return-object
			:data-cy="dataCy"
			density="compact"
			variant="outlined"
			hide-details
			:clearable="celarable"
			:disabled="disabled"
			:menu-props="{ closeOnContentClick: true }"
		>
			<template v-slot:item="{ props, item }">
				<v-list-item v-bind="props">
					<template v-slot:prepend>
						<v-radio-group v-model="selected" hide-details>
							<v-radio :label="item.label" :value="item.raw" />
						</v-radio-group>
					</template>
				</v-list-item>
				<v-divider />
			</template>
			<template v-slot:append-item>
				<slot name="append-option"></slot>
			</template>
		</v-select>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'RadioSelect',
	mixins: [ field ],
	props: {
		modelValue: [ Number, String, Object ],
		options: Array,
		dataCy: String,
		disabled: Boolean
	},
	data: () => ({
		model: null,
		selected: null
	}),
	computed: {
		allOptions() {
			if (!this.model) return this.options
			return [this.model, ...this.options]
		}
	},
	watch: {
		modelValue(value) {
			this.model = value
			this.selected = value
			this.validate()
		},
	},
	methods: {
		validate() {
			const validationFunctions = [this.validateRequired()]
			this.onErrors(validationFunctions)
		},
	},
	mounted() {
		this.model = this.modelValue
		this.selected = this.modelValue
		this.validate()
	},
}
</script>
