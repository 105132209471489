<template>
	<div class="ContractDetail">
		<FieldSet class="ContractDetail__title">
			<span class="contract-header" v-html="contractHeaderText"></span>

			<div style="margin: 16px 0">
				{{ $t('text.between') }} <b>{{ contract.legalName || $t('text.legalName') }}, {{ contract.legalAddress || $t('text.legalAddress') }} ({{ $t('text.referredToAs') }}) {{ contract.clientAbbreviation || $t('text.clientAbbreviation') }} </b>
				<br>
				{{ $t('text.and') }} <b><span class="contract-placeholer" v-if="!contract.signatureName">{{ $t('text.name') }}</span><template v-else>{{ contract.signatureName }}</template>, <span class="contract-placeholer" v-if="hasMissingAddressPart">{{ $t('text.address') }}</span><template v-else>{{ contractor.businessProfileData.streetAddress }}, {{ contractor.businessProfileData.zipCode }} {{ contractor.businessProfileData.city }}</template> ({{ $t('text.referredToAs') }}) LT</b>
			</div>

			<div v-if="contractValidFromText" style="margin: 16px 0">
				<b>
					{{ contractValidFromText }}
					{{ contractValidToText }}
				</b>
			</div>
		</FieldSet>
		<FieldSet>
			<div class="contract-text" v-html="contractTextForUi" />
		</FieldSet>
		<template v-if="showContractor">
			<div class="mt-8 d-flex flex-wrap">
				<FieldSet :label="$t('text.yourData')" :info-text="$t('text.yourDataInfoText')" class="flex-grow-1" />
				<v-spacer></v-spacer>
				<v-btn v-if="!pdfExportLoading && !readOnly" @click="$emit('openDialog')" class="gradientButton mt-4 mt-md-0" elevation="0" :disabled="terminated">
					<v-icon class="mr-1" size="20px">mdi-pencil</v-icon>{{ $t('text.editData') }}
				</v-btn>
			</div>
			<FieldSet class="mt-8" :label="$t('text.companyDetails')">
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'businessName'" />
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'companyEmail'" />
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'uidNumber'" />
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'taxId'" />
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'businessOwnerDateOfBirth'" />
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'businessOwnerPlaceOfBirth'" />
			</FieldSet>
			<FieldSet class="mt-8" :label="$t('text.companyAddress')">
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'streetAddress'" />
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'zipCode'" />
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'city'" />
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'country'" />
			</FieldSet>
			<FieldSet class="mt-8" :label="$t('text.communicationSetting')">
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'invoiceRecipientName'" />
				<ContractFieldDisplay :model="contractor" :field="'email'" />
			</FieldSet>
			<FieldSet class="mt-8" :label="$t('text.bankingDetails')">
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'accountHolder'" />
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'iban'" />
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'bic'" />
				<ContractFieldDisplay :model="contractor.businessProfileData" :field="'creditInstitution'" />
			</FieldSet>
		</template>
		<div v-if="showContractCard" class="d-flex mt-8">
			<ContractCard :contract="contract"
				:skip-termination-check="terminated"
				:style="{
					flex: !pdfExportLoading ? 0.4 : 1,
					minHeight: '130px',
					width: isMobile ? '100%' : 'auto',
					opacity: terminated ? '0.5' : '1',
				}"
			/>
		</div>
	</div>
</template>
<script lang="ts">
import Common from '../../mixins/Common.vue'
import ContractFieldDisplay from './ContractFieldDisplay.vue'
import ContractCard from './ContractCard.vue'
import FieldSet from '../../views/applications/packageDesigner/FieldSet.vue'
import { Contract, Contractor } from '../../../../api2/src/modules/contract/contract.entities'

export default {
	name: 'ContractDetail',
	components: { ContractFieldDisplay, FieldSet, ContractCard },
	mixins: [ Common ],
	props: {
		contract: { type: Object, required: true },
		contractor: { type: [Contractor, Object], required: true },
		pdfExportLoading: { type: Boolean },
		showContractor: { type: Boolean },
		terminated: { type: Boolean },
		showContractCard: { type: Boolean, default: false },
		readOnly: { type: Boolean, default: false },
	},
	data: () => ({
		windowWidth: window.innerWidth
	}),
	computed: {
		isMobile() {
			return this.windowWidth < 768
		},
		contractValidFromText() {
			const contractSignedDate = this.contract.signedDate
			if (contractSignedDate) return `${this.$t('text.validFrom')} ${new Date(contractSignedDate).toLocaleDateString(this.$store.state.selectedLocale)}`

			let contractStartDate = this.contract.startDate
			if (!contractStartDate) return null

			contractStartDate = new Date(contractStartDate)
			const today = new Date()
			if (contractStartDate < today) {
				return `${this.$t('text.validFrom')} ${today.toLocaleDateString(this.$store.state.selectedLocale)}`
			}
			return `${this.$t('text.validFrom')} ${contractStartDate.toLocaleDateString(this.$store.state.selectedLocale)}`
		},
		contractValidToText() {
			if (!this.contractValidFromText) return null
			if (!this.contract.endDate) return null
			const contractValidToDate = new Date(this.contract.endDate).toLocaleDateString(this.$store.state.selectedLocale)
			return `${this.$t('text.until')} ${contractValidToDate}`
		},
		contractText() {
			const { contract } = this
			const selectedLocale = this.$store.state.selectedLocale
			if (contract?.text) {
				if (contract.text[selectedLocale]) {
					return contract.text[selectedLocale]
				}
				const availableLocales = Object.keys(contract.text)
				if (availableLocales.length > 0) {
					return contract.text[availableLocales[0]]
				}
			}
			return ''
		},
		contractHeaderText() {
			return this.contract.header[this.$store.state.selectedLocale]
		},
		contractTextForUi() {
			// show empty paragraphs as new lines by adding non breaking space
			return this.contractText.replace(/<p>\s*<\/p>/g, '<p>&nbsp;</p>')
		},
		hasMissingAddressPart() {
			return !this.contract?.businessProfileData?.streetAddress || !this.contract?.businessProfileData?.zipCode || !this.contract?.businessProfileData?.city
		}
	},
	mounted() {
		window.addEventListener('resize', this.onResize)
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.onResize)
	},
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth
		},
	}
}
</script>
<style lang="scss">
@media print {
	.ContractDetail {
		margin: 0;
		padding: 0;
		
		.contract-header {
			display: block;
			margin-bottom: 24px;

			h1, h2, h3, h4, h5, h6 {
				line-height: 1.4;
				margin: 0;
				padding: 0;
				page-break-inside: avoid;
				page-break-before: avoid;
				page-break-after: avoid;
			}

			a {
				text-decoration: none;
				color: inherit;
			}
		}

		.FieldSet {
			page-break-inside: avoid;
		}
	}
}
.ContractDetail {
	&__title {
		padding-left: 24px;
	}

	.contract-header {
		margin-bottom: 24px;

		h1, h2, h3, h4, h5, h6 {
			line-height: 1.4;
			margin: 0;
			padding: 0;
		}

		a {
			color: #0066cc;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
	
	.contract-text {
		padding-left: 12px;
	}

	.contract-placeholer {
		color: #8F8F8F;
	}
}
</style>