import { Entity, EntityRepositoryType, PrimaryKey, Property } from '@mikro-orm/postgresql'
import { EntityRepository } from '@mikro-orm/postgresql'
import { Field, In, Control, Size, EnabledMarksSlim, MARKS_SLIM } from "../../framework/model-util";
import { BaseEntity } from '../core/core.entities'

class InfoTypeRepository extends EntityRepository<InfoType> {}

@Entity({ repository: () => InfoTypeRepository })
export class InfoType extends BaseEntity {
	[EntityRepositoryType]?: InfoTypeRepository

	@Property()
	@Field({ type: 'Symbol', localized: false, required: false })
	key?: string

	@Property()
	@Field({ type: 'Symbol', localized: true, required: true })
	name: Record<string, string>

	@Property()
	@Field({ type: 'Symbol', localized: false, required: false })
	icon?: string

	constructor(key: string) {
		super()
		this.key = key
	}
}
export class Info extends BaseEntity {
	@Property()
	title: string

	@In({ in: [ '<PROVIDED>' ] })
	@Control({ widgetId: 'radioSelect' })
	@Field({ type: 'Symbol', localized: false, required: true, hasOptions: true })
	infoType: string

	@Control({ settings: { maxHeight: '800' } })
	@Size({ min: 10, max: 50000 })
	@EnabledMarksSlim(MARKS_SLIM)
	@Field({ type: 'RichText', localizedAsChild: true, localized: true, required: true })
	description: Record<string, string>

	constructor(id: string) {
		super()
		this.id = id
	}
}
